import React from 'react';
import { oneOfType, object, number, string } from 'prop-types';
import { connect } from 'react-redux';
import { getFormattedNumber } from '../../utils/priceBreakdown';
import { getUserSessionAddonsWithDetails } from '../../redux/modules/session';

import './PriceItems.module.scss';

const mapStateToProps = (state) => ({
  addonsWithDetails: getUserSessionAddonsWithDetails(state),
});

function renderInfo() {
  return (
    <div className="price-items__info-wrapper">
      <span className="price-items__icon-info">i</span>
      <ul className="price-items__info">
        <li className="price-items__info-item">12% state tax</li>
        <li className="price-items__info-item">0.195% tourism tax</li>
        <li className="price-items__info-item">5.5% sustainability fee</li>
        <li className="price-items__info-item">
          <span className="price-items__info-lowercase">$40/night</span> resort fee
        </li>
      </ul>
    </div>
  );
}

function PriceItems({ room, addons }) {
  return (
    <div className="price-items">
      <div className="price-items__item">
        <strong>${getFormattedNumber(room.priceSubtotal)}</strong> Room
      </div>
      {addons > 0 && (
        <div className="price-items__item">
          <strong>${getFormattedNumber(addons)}</strong> Add-on(s)
        </div>
      )}
      <div className="price-items__item">
        <strong>${getFormattedNumber(room.priceSurcharges)}</strong> Taxes & Fees
        {renderInfo()}
      </div>
    </div>
  );
}

PriceItems.propTypes = {
  room: object.isRequired,
  addons: oneOfType([number, string]),
};

export default connect(mapStateToProps)(PriceItems);
